.scoreRow__score {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-items: center;
    background-color: #242124;
    color: white;
    font-size: 15px;
    font-family: 'Inter';
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    box-shadow: 3px 3px 7px black;
    transition: transform 1000ms;
}

.teamBox {
    display: flex;
    justify-content: center; /* Align horizontal */
    align-items: center; /* Align vertical */
}

.scoreRow a:hover {
    transform: scale(2.1); 
}