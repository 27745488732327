.teamsWrapper {
    display: flex;
    justify-content: center;
}

.gameTeamNavbar {
    display: flex;
    font-family: Roboto;
    justify-content: start;
    align-items: start;
    width: 90%;
    font-weight: 900;
    list-style-type: none;
    font-size: 17px;
    gap: 30px;
}

.gameTeamNavbar li {
    cursor: pointer; /* Changes the cursor to indicate it's clickable */
    transition: border-bottom 0.3s ease; /* Adds transition to the border */
}

.gameTeamNavbar li:hover {
    /* background-color: #f0f0f0; Light grey background on hover */
    border-bottom: 3px solid var(--primary-gold); /* Gold bottom border for the selected item */
}

.gameTeamNavbar li.selected {
    border-bottom: 3px solid var(--primary-gold); /* Gold bottom border for the selected item */
    
}