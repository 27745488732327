.teamsWrapper {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.teamNavbar {
    display: flex;
    font-family: Roboto;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    font-weight: 900;
    list-style-type: none;
    font-size: 17px;
    flex-wrap: wrap; /* Allow items to wrap into multiple rows */

}

.teamNavbar li {
    cursor: pointer; /* Changes the cursor to indicate it's clickable */
    transition: border-bottom 0.3s ease; /* Adds transition to the border */
}

.teamNavbar li:hover {
    /* background-color: #f0f0f0; Light grey background on hover */
    border-bottom: 3px solid var(--primary-gold); /* Gold bottom border for the selected item */
}

.teamNavbar li.selected {
    border-bottom: 3px solid var(--primary-gold); /* Gold bottom border for the selected item */
    
}

@media (max-width: 1350px) {
    .teamNavbar {
        width: 80%;
        flex-direction: row; /* Ensure items are in rows */
    }

    .teamNavbar li {
        flex: 1 1 45%; /* Adjust flex basis to fit two items per row */
    }
}

