@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Rambla:ital,wght@1,700&display=swap');


.player-stats-container {
    width: 90%;
    margin: 20px auto;
    font-family: 'Roboto';
    overflow-x: auto; /* Ensure horizontal scrolling */

  }
  
  .stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .stats-table th, .stats-table td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .stats-table th {
    background-color: #242124;
    color: #ffd700;
  }
  
  .stats-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .stats-table tr:hover {
    background-color: #ddd;
  }
  
  h1 {
    text-align: left;
    color: #333;
    font-weight: 900;
    font-size: 25px;
  }
  

  .player_column {  /* Sets static player column so table doesn't keep resizing */
    width: 15%;
  }