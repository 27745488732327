.hero {
    width: 100%;
    margin: auto;
}
.hero__image img {
    width: 100%;
}

.hero__image {
    position: relative;
}

.hero__title {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
}

.hero__tint {
    position: absolute;
    top: 0;
    background-color: rgba(0,  0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
}

.hero__image h1 {
    z-index: 3;
    color: var(--primary-gold);
    font-size: 50px;
    font-family: Roboto;
    font-weight: 900;
    text-align: center;
}

.hero__social__media {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.hero__social__media span {
    padding: 0 10px;
    margin: 0 20px;
    z-index: 3;
    color: var(--primary-gold);
    font-size: 25px;

}
