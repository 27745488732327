
.schedule {
    
}
.schedule__table__container {
    display: flex;
    justify-content: center;
    border-radius: 15px;
}

.schedule__table {

}

.matchContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.schedule__accordion {
    padding: 0px 0px;
}

#schedule__accordion__header {
    padding-left: 3em;
}