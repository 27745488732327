@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --primary-gold: #ffd700
}


.app__announcement {
  display: flex;
  background-color: var(--primary-gold);
  font-family: Roboto;
  font-weight: 900;
  text-align: center;
  padding: 5px 0;
  width: 100%;
}

.app__announcement p {
  flex: 1;
}

.app_players {
  display: flex;
  gap: 30px;
}
