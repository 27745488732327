.app__announcement {
    display: flex;
    background-color: var(--primary-gold);
    font-family: Roboto;
    font-weight: 900;
    text-align: center;
    padding: 5px 0;
    width: 100%;
  }
  
  .app__announcement p {
    flex: 1;
  }

.navbar {
    display: flex;
    font-family: Roboto;
    font-weight: 900;
    font-size: 16px;
    align-items: center;    
    margin: auto;
    padding: 20px 20px;
    max-width: 1140px;
}

.navbar__logo {
    width: 116px;
}

.navbar__middle {
    display: flex;
    flex: auto;
    justify-content: center;
}

.navbar__middle a {
    text-decoration: none;
    color: black;
    padding: 0 20px;
}

.navbar__right {
    display: flex;
}

.navbar__right button {
    background: none;
    border: none;
    padding: 12px 25px;
    background-color: black;
    color: white;
    font-family: Roboto;
    font-weight: 900;
    letter-spacing: 2px;
}

.navbar__toggle {
    display: none;
}

@media (max-width: 768px) {

    .navbar__left {
        display: block;
        width: 68px;
    }

    .navbar__left__open {
        display: none;
    }

    .navbar__toggle {
        display: flex;
        font-size: 40px;
        /* margin-left: auto; */
        cursor: pointer;
        color: #ffd700;
        /* align-items: center; */
        justify-content: end;
    }

    .navbar__menuburger {
        /* font-size: 68px; */
    }

    .navbar {
        flex-direction: row;
        align-items: start;
        background: #242124;
        justify-content: space-between;
    }

    /* When the menu is opening, the flex direction is column */
    .navbar__open {
        flex-direction: column;
        align-items: start;
        background: #242124;
        padding: 20px;

    }

    .navbar__middle {
        display: none; /* Set to none for default */        
    }

    .navbar__middle__open a {
        width: 100%;
        text-align: center;
        margin: 0.2rem 0.5rem;
        color: #ffd700;
        /* font-weight: 900; */
    }

    .navbar__middle__open {
        display: flex; /* Set to none for default */
        flex-direction: column;
        width: 100%;
        flex: auto;
        justify-content: center;
        align-items: center;
    }

    .navbar__right {
        display: none;
    }

}
