.scores-container {
    width: 90%;
    margin: auto;
}

.scores-container h1 {
    text-align: left;
    color: #333;
    font-weight: 900;
    font-size: 25px;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--primary-gold); /* Gold bottom border for the selected item */
    margin-bottom: 20px;
}